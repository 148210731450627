const binding = { modules: {}, dataActions: {} };

    (binding.modules['cec-funeralcare'] = {
        c: () => require('partner/themes/cec-funeralcare/cec-funeralcare.tsx'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['accordion','account-customer-balance','account-loyalty','buybox','cart','checkout-order-confirmation','content-block','image-list','media-gallery','navigation-menu','order-confirmation','order-details','order-history','promo-banner','text-block'],
        iNM: false,
        ns: '__local__',
        n: 'cec-funeralcare',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'cec-funeralcare.theme.settings.json',
        md: 'src/themes/cec-funeralcare'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };